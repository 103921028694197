.app__footer {
  /*height: 194px;*/
  height: 95px;
  width: 100%;
  background-color: $brand_alt;
  box-sizing: border-box;
  /*padding:5px 0 0;*/
  padding: 24px 0 95px;
  font-family: $main_font;
  border-bottom: 4px solid #3f3f3f;
  text-align: left;
  margin: 0;
  background-size: 94%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.app__footer a,
.admin .app__footer a {
  font-weight: 400;
}

.app__footer__container {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  p {
    font-size: 8px;
    line-height: 10px;
    margin: 0 0 4px;
  }
  position: relative;

  .left-col {
    float: left;
    width: 60%;
  }
  .right-col {
    float: right;
    padding-top: 33px;
  }
}

.app__footer__nav {
  font-size: 14px;
  font-family: $admin_font;
  margin-bottom: 4px;
}

.app__footer__nav-list {
  list-style: none;
  padding-inline-start: 0;
  margin: 0;
}

.app__footer__nav-item {
  display: inline-block;
  margin-right: 26px;
}

.app__footer__nav-item a {
  text-decoration: underline;
}

.app__footer__smalltext {
  font-size: 9px;
  line-height: 13px;
  color: $yet_another_greyx;
}

.footer__logo {
  width: 175px;
  margin-bottom: 0;
}

.footer__pipe {
  color: $brand_accent;
  margin: 0 2px;
}

footer ul.footer-menu li {
    float: left;
    margin: 0;
}

footer ul.footer-menu li a {
  font-size: 8px;
  color: #000;
}

footer ul.footer-menu {
    list-style: none;
    margin: 0 auto 30px;
    padding: 0;
}

footer ul.footer-menu li:not(:first-child):after {
    color: #ffd100;
    content: " | ";
    margin: 0 4px;
    font-size: 8px;
    float: left;
}

footer .footer-callout {
    width: 100%;
    max-width: 286px;
    position: absolute;
    right: 0; 
    top: -10px;
    font-size: 12px;
}

footer .footer-callout .app .store-links img {
    height: 31px;
    margin-right: 8px;
}

footer .footer-callout {
    background: #ffd100;
    font-size: 11px;
    margin: 30px 0 0;
    .survey {
      padding: 10px;
      h4 {
        display: inline-block;
        margin: 0;
        line-height: 13px;
      }
      p {
        display: inline;
        font-size: 12px;
        font-weight: 600;
      }
    }
    a {
      font-size: 12px;
      color: #000;
      text-decoration: underline;
      display: inline;
      float: none;
    }
}

footer .footer-callout .app {
    padding: 10px;
    background: #303030;
    color: #fff;
    h4 {
      font-size: 12px;
      margin: 0 0 8px;
    }
}

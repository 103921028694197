.app__header {
  width: 100%;
  border-top: 0;
  border-bottom: 12px solid #c1c1c1;
  height: 123px;
  background-color: $header_color;
}

.app__header__container {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;

}

.app__header__profile {
  position: absolute;
  top: 18px;
  right: 0;
  text-align: left;
  display: flex;

}

.app__icon.app__header__icon {
  margin-top: 5px;
}

.app__header__username {
  font-weight: 300;
  font-size: 13px;
  letter-spacing: 0.14px;
  line-height: 13px;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  color: white;
  margin-right: 10px;
  span {
    text-transform: capitalize;
    color: $brand_accent;
    font-size: 9px;
  }
  margin-top: 13px;
  min-width: 60px;
}

.app__header__tabs {
  margin-right: 156px;
  display: none;
}

.app__header__tab {
  width: 154px;
  height: 44px;
  float: right;
  margin-left: 3px;
  border-radius: 0 0 12px 12px;
  cursor: pointer;
  -webkit-box-shadow: 0px 6px 15px -5px rgba(140,134,134,0.74); 
  box-shadow: 0px 6px 15px -5px rgba(140,134,134,0.74);
  background-color: #fff;
  
  &:last-of-type {
    
  }
}

.app__logo {
  height: 71px;
  width: 126px;
  background-image: url(/img/maci_login.png);
  background-size: contain;
  position: relative;
  top: 21px;
}

.app__pagenavigation {
  position: fixed;
  bottom: 20px;
   z-index: 1000;
  width: 100%;
}

.app__pagenavigation__icon {
  width: 42px;
  height: 42px;
  border-radius: 6px;
  background-color: $brand_main;
  cursor: pointer;
  float: left;
  margin: 0 0 0 55px;
  text-align: center;
  i {
    color: $brand_accent;
    font-size: 28px;
    margin: 6px 0 0 -2px;
  }
  &:nth-child(2) {
    float: right;
    margin: 0 55px 0 0;
    transform: rotate(180deg);
  }
  &:nth-child(2).send {
    border-radius: 6px;
    float: right;
    margin: 0 24px 0 0;
    transform: rotate(0);
    background-image: url(/img/send-button.svg);
    background-repeat: no-repeat;
    background-size: 110px auto;
    background-position: 5px 11px;
    width: 120px;
    i { 
      display: none;}
  }
  &:hover {
    opacity: 0.8;
  }
}

.back-button {
  font-size: 12px;
}

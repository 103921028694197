.contact__modal .app__modal__container {
  height: 480px;
  overflow: hidden;
}

.contact__warning__modal .app__modal__container {
  height: 250px;
  width: 515px;
  color: #707070;
}

.contact__modal.title {
  background: transparent;
  z-index: 6001;
  pointer-events: none;

  .app__modal__container {
    margin-top: calc(50vh - 278px) !important;
    height: 5px;
    background: none;
    box-shadow: none;
    width: 720px; /* todo: test cross browser */
    overflow: hidden;

    p {
      background-color: #fff;
      float: left;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0px;
      padding: 10px 20px;
    }
  }
}

.contact__modal .app__modal__container {
  margin-top: calc(50vh - 230px) !important;

}

.contact__warning__modal .app__modal__container h3 {
  color: $admin_warning_2;
  font-size: 15px;
}

.contact__modal button {
  outline: none;
  cursor: pointer;
}

.contact__modal button:disabled,
.skin2 .contact__modal button:disabled {
  background-color: gray;
}

button.app__contact-add {
  background-color: $brand_main;
  margin: 0 8px 0 0;
  color: $brand_accent;
  text-transform: uppercase; 
  border-radius: 0;
  position: relative;
  top: -10px;
}


.contact__modal h4 {
  font-size: 14px;
  padding: 5px 8px;
  margin: 0 0 10px;
  text-align: left;
  float: left;
  background-color: lightgrey;
  text-transform: uppercase;
  font-weight: 400;
}

.app__contact__info-wrapper {
  margin-top: 30px
}

.app__contact__input-text__label {
  float: left;
  margin-right: 15px;
  margin-bottom: 5px;
}

.app__contact__input-text__label.clearfix {
  clear: left;
}

.app__contact__input-text__label-text {
  font-size: 10px;
  text-transform: uppercase;
  box-sizing: border-box;
  color: #000;
  padding-top: 5px;
  padding-bottom: 10px;
  text-align: left;
}

.app__contact__input-text {
  height: 32px;
  width: 160px;
  box-sizing: border-box;
  border: 1px solid $med_grey;
  font-size: 12px;
  padding-left: 15px;
}

.app__contact__input-text.specialty {
  width: 145px;
}

.app__contact__input-text.npi {
  width: 110px;
}

.app__contact__input-text.state {
  width: 51px;
}

.app__contact__input-text.zip {
  width: 84px;
}

.app__contact__bottom {
  overflow-y: auto;
  width: 550px;
}

/******/
.app__contact__add-edit-wrapper {
  margin-top: 15px;
  border-top: 1px solid black;
  clear: both;

  .error {
    float: left;
    position: absolute;
    font-size: 13px;
    padding: 7px 23px 7px 16px;
    color: $brand_main;
  }
}

.app__contact-add-edit-buttons {
  font-size: 11px;
  float: right;
  padding: 5px 0px;
  width: 100%;
  color: $brand_accent;
  button {
    background-color: $brand_main !important;
    border-radius: 0 !important;
  }
}

.checkbox {
  width: 20px;
  height: 20px;
  &:checked {
    background-image: url(/img/check.svg) !important;
  }
}

.contact__modal .checkbox-round {
  border: 1px solid lightgrey;
  margin: 0 8px 0 0;
  height: 18px;
  width: 18px;
  &:disabled {
    opacity: .5;
  }
}

.app__contact__email-forms {
  clear: both; 
  //overflow: auto;
  padding: 0px 0px 15px 15px;
  height: auto;
}

.app__contact__hcp-emails {
  float: left;
  width: 302px;
  
}

.app__contact__ma-emails {
  float: left;
  width: 320px;
  h4 {
    margin-top: 10px;
  }
}

.app__contact__hcp-emails, .app__contact__ma-emails {

  }

.skin2 .contact__modal button.app__contact-add-edit,
.contact__modal button.app__contact-add-edit {
  padding: 0px 3px;
  color: $brand_accent;
  background: none;
  margin: 5px;
  width: auto;
  margin-left: 0px;
  float: right;
  background-color: none;
  font-size: 10px;
  padding: 2px 5px;
  text-transform: uppercase;

}

.contact__modal button.app__contact-add-edit.disabled {
  display: none;
}

.contact__modal button.app__contact-add-edit.active {
  color: white;
}

.skin2 .contact__modal button.app__contact-add-edit.active {
  color: $form_element_color_2;
}

.skin3 .contact__modal button.app__contact-add-edit.active {
  color: $form_element_color_3;
}

.skin2 .contact__modal button.close,
.skin2 button.app__contact-save ,
.skin2 button.app__contact-add {
  background-color: $brand_main;
  outline: none;
}

.skin3 .contact__modal button.close,
.skin3 button.app__contact-save ,
.skin3 button.app__contact-add {
  background-color: $form_element_color_3;
  outline: none;
}


.contact__warning__modal button {
  background: $empty_color;
  margin-left: 0px;
  padding: 0px;
  text-transform: uppercase;
  margin-top: 20px;
  height: 38px;
}

.app__contact__button {
  height: 32px;
  width: 150px;
  box-sizing: border-box;
  background-color: $navigation_color;
  color: $text_white;
  bottom: 2px;
  border: none;
  font-size: 13px;
  font-weight: 700;
  border-radius: 0;
  cursor: pointer;
  float: right;
  clear: right;
  margin-bottom: 20px
}

.app__contact__footer {
  overflow: hidden;
  font-size: 11px;
  float: left;
  width: 60%;
}

.contact__modal .close {
  border-radius: 0;
  background-color: $brand_main;
  color: $brand_accent;
}

.app__contact__footer-info {
  text-align: left;
}

.app__contact__footer span {
  display: block;
  font-weight: bold;
  color: $brand_alt;
  margin-bottom: 4px;
}

.skin2 .app__contact__footer span {
  color: $form_element_color_2;
}

.skin3 .app__contact__footer span {
  color: $form_element_color_3;
}

button.app__contact-add, button.app__contact-save {
  float: right;
  margin-top: -15px;
}

.app__contact__add-edit-wrapper input[type="text"] {
  width: 250px;
  padding: 3px;
  height: 32px;
  box-sizing: border-box;
  border: 1px solid $med_grey;
  font-size: 12px;
  padding-left: 15px;
}

.app__contact__add-edit-wrapper input[type="text"]:disabled {
  background-color: #f7f7f7;
}

.app__contact__add-edit-wrapper .app__contact__input-text-email.primary {
    border: 3px solid $brand_accent;
}


.skin2 .app__contact__add-edit-wrapper .app__contact__input-text-email.primary {
  border: 3px solid $form_element_color_2;
}

.edit__group__warning__modal .app__modal__container {
  height: 371px;
}

.delete__group__warning__modal {

  .app__modal__container {
    height: 105px;
    width: 300px;
    overflow: hidden;

    h3 {
      float: left;
    }
  }

  button {
    float: right;
    clear: none;
    background-color: $empty_color;
    margin-left: 0px;
    padding: 0px;
    text-transform: uppercase;
    margin-top: 20px;
    border-radius: 18px;
    height: 38px;
  }

  button.cancel {
    background-color: white;
    color: $empty_color;
  }
}

.add__modal  {
  .app__contact__bottom {
    margin-top: 35px;
    height: auto;
  }
}

@import "variables";
@import "Footer";
@import "Header";
@import "Steps";
@import "Tabs";
@import "PageNavigation";
@import "Modal";
@import "Recipients";
@import "Profile";
@import "EmailGrid";
@import "Attachments";
@import "AdminTemplate";
@import "CustomSelect";
@import "Misc";
@import "ContactModal";
@import "ISI";

// 'static' ie non-configurable version

body {
  margin: 0;
  padding: 0;
  * {
    box-sizing: border-box;
    font-family: $main_font;
    letter-spacing: .1px;
  }
}

a {
  text-decoration: none;
}


.landscapeOverlay {
  display: none;
}

.app__block-heading {
  background-color: $brand_main;
  text-align: left;
  color: white;
  height: 44px;
  font-size: 16px;
  font-weight: 200;
  text-transform: uppercase;
  padding: 11px 13px;
  i {
    color: $brand_accent;
    font-size: 13px;
    position: relative;
    top: -1px;
    margin: 0 3px;
  }
  margin-top: 15px;
}

.fake-link {
  height: 20px;
  display: inline-block;
  border-bottom: 1px solid white;
  cursor: pointer;
}

.container {
  max-width: 960px;
  margin: 0 auto;
}

@media screen and (max-device-width: 1020px) {
  .landscapeOverlay {
    background-color: #203045;
    z-index: 100000;
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: -10px;
    text-align: center;
    padding-top: 5%;
    color: white;
    font-size: 12px;
    right: 0px;
    bottom: 0px;
  }
}

.section-heading {
  background: $brand_main;
  display: inline-block;
  min-width: 120px;
  width: auto;
  text-align: center;
  padding: 4px 10px;
  font-weight: 200;
  color: $brand_accent;
  text-transform: uppercase;
  font-size: 13px;
  margin-bottom: 26px;
}

///

.app__container {
  box-sizing: border-box;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  /*border-left: 1px solid $border_color;
	border-right: 1px solid $border_color;*/
  padding-bottom: 80px;
  min-height: calc(100vh - 95px);
  font-family: $main_font;
  /*min-height: 100vh;*/
}

///

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* html .clearfix {
  zoom: 1;
} /* IE6 */
*:first-child + html .clearfix {
  zoom: 1;
} /* IE7 */

.checkbox-round {
  width: 14px;
  height: 14px;
  background-color: white;
  vertical-align: middle;
  border: 0;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin: 7px 7px 0 0;
}
.checkbox-round:checked {
  background-color: $brand_accent;
  background-image: url(/img/check.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 1px;
}

.skin2 .checkbox-round:checked {
  background-color: $form_element_color_2;
}

.checkbox-square {
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 0;
  vertical-align: middle;
  border: 1px solid $table_border;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin: 1px 7px 0 0;
}

.check-circle {
  background-image: url(/img/check-circle.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 52px;
  width: 52px;
}

.app__center {
  margin: 0 auto;
}

// MISC

.app__email-preview {
  width: 480px;
  min-height: 443px;
  box-sizing: border-box;
  background-color: #333;
}

.app__select-topic {
  font-family: $main_font;
  margin-top: 10px;
  padding: 0 15px;
  box-sizing: border-box;
  text-align: left;
  p {
    font-size: 12px;
  }
}

.app__dropdowns {
  position: absolute;
}

.select.app__select-topic__top-select {
  width: 100%;
  max-width: 812px;
}

.app__select-topic label {
  font-weight: 400;
  color: $brand_main;
  font-size: 12px;
  padding: 0px 0px 10px 0px;
  display: block;
  text-transform: uppercase;

}

/* Reset Select */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #fff;
  background-image: none;
  font-size: 12px;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}

.app__select-topic__button {
  width: 200px;
  height: 32px;
  color: $brand_accent;
  background-color: $brand_main;
  text-transform: uppercase;
  font-weight: 300;
  border: none;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  &:hover {
    background-color: $brand_alt;
  }
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 210px;
  height: 30px;
  line-height: 3;
  /*background: #2c3e50;*/
  overflow: hidden;
  border: 1px solid $form_element_color;
  margin-bottom: 25px;
}
select {
  flex: 1;
  padding: 0 0.5em;
  color: #333;
  cursor: pointer;
  border-radius: 0;
}
/* Arrow */
.select::after {
  box-sizing: border-box;
  color: $text_white;
  /*font-family: "Font Awesome 5 Free"; 
  font-weight: 900;
  font-size: 20px;*/
  content: url(/img/down-arrow.svg);
  position: absolute;
  top: -9px;
  right: 0;
  padding: 0px 9px;
  height: 39px;
  background: $form_element_color;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
/* Transition */
.select:hover::after {
  color: #eee;
}

.unselectable {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Alternate Branding */

.skin2 {
  .select::after {
    background: $form_element_color_2;
  }
  .select {
    border: 1px solid $form_element_color_2;
  }
  .app__select-topic label {
    color: $form_element_color_2;
  }
  .check-circle {
    background-image: url(/img/check-circle_grey.svg);
  }

  .checkbox-round:checked {
    background-color: $form_element_color_2;
  }
}


.skin3 {
  .select::after {
    background: $form_element_color_3;
  }
  .select {
    border: 1px solid $form_element_color_3;
  }
  .app__select-topic label {
    color: $form_element_color_3;
  }
  .check-circle {
    background-image: url(/img/check-circle_grey.svg);
  }

  .checkbox-round:checked {
    background-color: $form_element_color_3;
  }
}

/*------*/

.app__init-menu {
  text-align: center;
  padding-top: 0;
  min-height: calc(100vh - 234px);
  padding-bottom: 50px;
  .app__block-heading {
    margin-top: 15px;
  }
}

.init-menu__container {
  display: flex;
  justify-content: center;
  padding: 70px 0 100px;
  background-image: url(/img/menu_bg.png);
  background-size: cover;
  height: 430px;
}

.init-menu__item {
  box-sizing: border-box;
  width: 245px;
  height: 226px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  background-color: $brand_accent;
  color: $brand_main;
  margin: 0 20px;
  padding-top: 45px;
  font-size: 22px;
  font-weight: 200;
  cursor: pointer;
  img {
    height: 103px;
    margin-bottom: 12px;
  }
  &:nth-of-type(2) {
    background-color: $brand_main;
    color: $brand_accent;
    img {
      height: 90px;
      margin: 7px auto 18px;
    }
  }
}



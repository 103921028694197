.app__modal {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 6000;
  background: rgba(0, 0, 0, 0.3);
  font-family: $main_font;
}

.video__modal,
.document__modal,
.contact__modal,
.contact__warning__modal {
  left: 0;
  top: 0;

  button {
    padding: 10px 20px;
    color: #000;
    border: 0;
    background-color: $brand_accent;
    border-radius: 6px;
    margin-left: 10px;
    font-size: 14px;

    &.close {
      padding: 0px 13px 4px;
    }
  }

  .app__modal__container {
    width: 640px;
    height: 420px;
    padding: 20px 40px 40px 40px;
    margin-top: calc(50vh - 250px);
    overflow: scroll;

    button.close {
      float: right;
      margin-bottom: 20px;
      border: 0;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .react-pdf__Document {
    margin-top: 28px;
  }
}

.skin2 {
  .document__modal {
    button {
      background-color: $form_element_color_2;
    }
  }
}

.app__attachments__video,
.app__attachments__image {
  cursor: pointer;
}

.activity-loader {
  text-align: center;
}

.app__profile__activity-search {
  float: right;
  clear: right;
  width: 20%;
  padding: 14px 0px 20px 0px;
  text-align: right;
  
  input {
    color: #383737;
    font-size: 13px;
    border-radius: 0px;
    padding: 7px 13px;
    border: 1px solid #b7b7b7;
    width: 85px;
    margin: 0px 5px;
  }
}

.app__profile__activity-actions {
  padding: 14px 0px 20px 0px;
  display: inline-block;
  width: 80%;
  float: left;

  .react-datepicker-wrapper, .divider {
    float: left;
  }

  .divider {
    padding-top: 5px;
    color: #b7b7b7;
  }

  .react-datepicker__input-container input {
    color: rgb(56, 55, 55);
    font-size: 13px;
    border-radius: 0px;
    padding: 7px 13px;
    border: 1px solid #b7b7b7;
    width: 100px;
    margin: 0px 5px;
  }

  label {
    margin-left: 30px;
    font-size: 13px;
    margin-top: 8px;
    display: inline-block;
  }

  select {
    border: 1px solid #b7b7b7 !important;
    padding: 7px;
    margin-left: 6px;
    width: 100px;
  }

  .activity-action-submit {
    float: right;
    height: 32px;
    width: 70px;
    background-color: #2699fb;
    color: #ffffff;
    border: none;
    font-size: 13px;
    font-weight: 700;
    border-radius: 6px;
    cursor: pointer;
    margin-right: 35px;
  }
}

.app__profile__activity-table {

  h3 {
    width: 100%;
    clear: both;
    text-align: center;
    padding-top: 30px;
    color: #515050;
  }

  table {
    border-collapse: collapse !important;
  }

  .-sort-desc {
    box-shadow: none !important;
    &:before {
      content: "▼";
      float: right;
    }
  }

  .-sort-asc {
    box-shadow: none !important;
    &:before {
      content: "▲";
      float: right;
    }
  }

  th {
    width: 16% !important;
    padding: 5px 20px;
    border: 5px solid #fff;
  }

  tbody {
    tr {
      border-bottom: 1px solid $med_grey !important;
    }

    td {
      line-height: 18px;
      padding: 3px;
      white-space: pre-line;
      &:last-child {
        white-space: pre-wrap;
      }
    }
  }

  thead td {
    border: 5px solid #fff;
    white-space: pre-wrap;
    &:last-child {
      white-space: pre-wrap;
    }
    &:nth-child(3) {
      white-space: pre-wrap;
    }
  }
}

.app__profile__input-text__label-text[disabled] {
  background-color: #c4bfbf;
}
.app__profile__input-text[disabled] {
  color: #c4bfbf;
}

.pagination {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  margin-top: 15px;

  button {
    background-color: $brand_main;
    padding: 5px 8px;
    width: 30px;
    border: none;
    color: $brand_accent;
    cursor: pointer;
    margin-right: 10px;
  }

  select {
    font-size: 12px;
    padding: 10px 16px;
    background-color: $brand_main;
    color: $brand_accent;
    font-weight: 300;
    text-transform: uppercase;
    border: none;
  }

  input[type="number"] {
    font-size: 15px;
    margin-right: 15px;
  }
}

.gridContainer,
.templateContainer {
  display: block;

  .previewSubject {
    color: $brand_main;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }
}

.templateContainer iframe {
  background-color: white;
  border: 1px solid $light_grey;
}

.skin2 {
  .templateContainer {
    display: block;

    .previewSubject {
      font-family: Arial, sans-serif;
      color: $form_element_color_2;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .pagination {
    button, select {
      background-color: $form_element_color_2;
    }
  }
}
.skin3 {
  .templateContainer {
    display: block;

    .previewSubject {
      font-family: Arial, sans-serif;
      color: $form_element_color_3;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .pagination {
    button, select {
      background-color: $form_element_color_3;
    }
  }
}


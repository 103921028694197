$brand_main: #555555;
$brand_alt: #a0a0a0;
$brand_accent : #FFD102;

$header_color: #2a2a2a;

$main_font: 'Poppins', sans-serif;

$light_grey: #d5d5d5;





$border_color: #edebef; //lightish grey

$navigation_color: #2699fb; //royalish blue
$navigation_hover: #127fdb;

$icon_border: #480d7a; //purple;
$profile_text: #480d7a; //purple;

$text_color: #4c4c4c; //dark grey
$text_black: #050505;
$alt_text_color: #383737; //another grey;
$exlight_grey: #f2f2f2;
$med_grey: #b7b7b7; //med dark grey
$med_grey2: #c4c4c4;
$text_white: #ffffff;
$tab_color: #070606; //dark grey
$tab_border: #480d7a; //purple;
$table_border: #707070; //med dark grey
$table_dividers: #118568;
$table_select: #c3c3c3;
$yet_another_grey: #a7a7a7;
$yet_another_greyx: #616161;
$alert_red: #fd0d1b;
$empty_color: #2699fb;

/* THEME 1 */

$form_element_color: #480d7a; //purple
$secondary_form_color: #c8da2d; //avocado
/* THEME 2 */

$form_element_color_2: #cf9c71; //copper
$secondary_form_color_2: #b7b7b7; //grey
$tab_border_2: #cf9c71; //


/* THEME 3 */
$form_element_color_3: #54af89; //green
$secondary_form_color_3: #7ebbe3; //light blue
$tab_border_3: #D7F2EC;
$highlight_3: #426595;

/* LOGIN SECTION */

$admin_header_color: #1b76be;
$admin_glow: #e0ecf7;
$admin_font: Raleway, Arial, sans-serif;
$admin_text: #1d2226;
$admin_link: #ed7622;
$admin_warning: #ed7622;
$admin_warning_2: #fd0d1a;
$admin_heading: #042e6c;
$admin_form_element: #c5c5c5;
$admin_form_element2: #b7b7b7; //med dark grey
$admin_form_label: #939697;
$admin_button_color: #ed7622;
$admin_button_shadow: #d1e7e1;

.app__modal {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 6000;
  background: rgba(0, 0, 0, 0.3);
  font-family: $main_font;
}

.app__modal__container {
  width: 540px;
  height: 355px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #fff;
  margin: 0 auto;
  margin-top: calc(50vh - 176px);
  position: relative;
}

.app__modal__great-job {
  text-align: center;
  padding-top: 30px;
}

.app__modal__heading {
  font-size: 37px;
  color: $brand_main;
  font-weight: 600;
  text-transform: uppercase;
}

.app__modal__text {
  font-size: 29px;
  color: $brand_accent;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.app__modal .loading {
  width: 250px;
  position: relative;
  top: -10px;
}

.app__modal__green-check {
  position: absolute;
  left: 360px;
  top: 56px;
}

.app__modal__icons {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 35px;
}

.app__modal__icon-container {
  width: 50%;
  text-align: center;
}

.app__modal__thumb {
  font-size: 38px;
}

.app__modal__icon {
  width: 70px;
  height: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto 9px;
  cursor: pointer;
}

.app__modal__icon-link {
  font-size: 16px;
  text-decoration: underline;
  color: $brand_main;
  font-weight: 400;
  text-transform: uppercase;
  &:hover {
    color: $navigation_hover;
  }
  cursor: pointer;
}

.app__modal__icon--sendagain {
  background-image: url(/img/envelope-open.svg);
}

.app__modal__icon--review {
  background-image: url(/img/analytics-monitor.svg);
}

.skin2 {
  .app__modal__heading,
  .app__modal__text {
    color: $form_element_color_2;
  }
}

.skin3 {
  .app__modal__heading,
  .app__modal__text {
    color: $form_element_color_3;
  }
}

.video__modal .app__modal__container{
  width: 100vw !important;
  height: 100vh !important;
  position: relative;
  margin: 0 !important;
  padding: 0 !important;

  video {
    position: absolute;
    width: 100vw !important;
  height: 100vh !important;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  }
  .video__close {
    position: absolute;
    z-index: 10;
    top: 15px;
    right: 15px;
    background-color: $brand_main;
    border-radius: 0;
    color: $brand_accent;
  }
}

.import__modal.contact__modal {

  .app__modal__container {
    height: 300px;
    overflow: hidden;
  }

  .app__modal__container {
    p {
      text-align: left;
      font-size: 19px;
      color: #555555;
      margin: 15px 0px 25px 0px;
    }
  }
}
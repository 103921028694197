.app__attachments {
  /*background-color: $exlight_grey;*/
  width: 100%;
  height: 100%;
  padding: 0;
  box-sizing: border-box;
  font-family: $main-font;
  position: relative;
  margin-bottom: 6px;
  text-align: left;
}

.app__attachments__prompt {
  font-size: 12px;
  color: $brand_main;
  margin: 17px 0 18px;
  display: block;
  font-weight: 300;
}

.app__attachments__subject {
  color: #555555;
  padding-top: 10px;
}

.app__attachments__images {
  /*margin-bottom: 36px;*/
  display: flex;
  flex-wrap: wrap;
}

.app__attachments__images p,
.app__attachments__videos p {
    text-align: left;
    font-size: 11px;
    line-height: 12px;
    color: $brand_main;
    letter-spacing: 0;
}

.app__attachments__header {
  background-color: $brand_main;
  height: 25px;
  width: auto;
  text-align: right;
  .checkbox-round {
    height: 13px;
    width: 13px;
    margin: -2px 6px 0 0;
  }
}

.app__attachments__image-container {
  flex: 0 0 calc(14%);
  float: left;
  margin-bottom: 18px;
  margin-right: 18px;
  &:last-of-type {
    margin-right: 0;
  }
  text-align: left;
}

.app__attachments__image {
  position: relative;
  left: 0;
  height: 153px;
  /*background-image: url(/img/cat1.jpg);*/
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: $light_grey;
}

//these styles just for demo! Remove!
.app__attachments__image-container {
  &:nth-of-type(1) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
  &:nth-of-type(2) .app__attachments__image {
    background-image: url(/img/cat3.jpg);
  }
  &:nth-of-type(3) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
  &:nth-of-type(4) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
  &:nth-of-type(5) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
  &:nth-of-type(6) .app__attachments__image {
    background-image: url(/img/cat4.jpg);
  }
  &:nth-of-type(7) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
}

.app__attachments__video-container {
  &:nth-of-type(1) .app__attachments__video {
    background-image: url(/img/videothumb1.png);
  }
  &:nth-of-type(2) .app__attachments__video {
    background-image: url(/img/videothumb2.png);
  }
  &:nth-of-type(3) .app__attachments__video {
    background-image: url(/img/videothumb1.png);
  }
  &:nth-of-type(4) .app__attachments__video {
    background-image: url(/img/videothumb1.png);
  }
}

//

.app__attachments__videos {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
}

.app__attachments__video-container {
  flex: 0 0 23%;
  min-width: 220px;
  margin-right: 15px;
  &:last-child {
    margin-right: 0;
  }
  padding: 0 0 10px;
}
.app__attachments__video {
  height: 129px;
  /*background-image: url(/img/cat1.jpg);*/
  background-size: cover;
  background-position: 50% 50%;
}
.skin2 {
  .app__attachments__images p,
  .app__attachments__videos p {
    color: $table_border;
  }
  .app__attachments__prompt {
    color: $form_element_color_2;

  }
  .app__attachments__header {
    background-color: $form_element_color_2;
  }
}
.skin3 {
  .app__attachments__images p,
  .app__attachments__videos p {
    color: $table_border;
  }
  .app__attachments__prompt {
    color: $form_element_color_3;
  }
  .app__attachments__header {
    background-color: $form_element_color_3;
  }
}
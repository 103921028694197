.app__main-tabs {
  text-align: left;
  margin-bottom: 10px;
}

.app__main-tabs__list {
  list-style: none;
  padding-inline-start: 0;
  margin-bottom: 0;
}

.app__main-tabs__tab {
  font-family: $main_font;
  font-size: 14px;
  font-weight: 200;
  color: $brand_accent;
  text-transform: uppercase;
  width: 19%;
  height: 36px;
  background-color: #828282;
  display: inline-block;
  margin: 0 8px 8px 0;
  padding-top: 8px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  &:first-of-type {
   /* background-color: $brand_accent;
    color: $brand_main;*/
  }
}

.app__main-tabs__tab--selected {
  background-color: $brand_main;
}

.templateSelectContainer {
  p {
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    margin: 0;
  }
}


.admin a {
  color: $brand_accent;
  float: right;
  font-size: 12px;
}

.admin .app__container {
  border: 0;
  min-height: calc(100vh - 95px);
  font-family: 'Poppins', sans-serif;
}

.admin .app__header {
  background-color: $admin_header_color;
  background-image: none;
  margin-bottom: 46px;
  display: none;
}

.admin .app__login-panel {
  box-sizing: border-box;
  width: 838px;
  height: 535px;
  border-radius: 0;
  box-shadow: 0;
  margin: 0 auto;
  padding: 30px 30px 0;
  font-size: 14px;
  margin-bottom: 48px;
  position: relative;
  display: flex
}

.admin .app__left-panel {
  width: 49%;
  background-image: url(/img/login-image.png);
  background-size: cover;
  margin-right: 2%;
}

.admin .app__right-panel {
  width: 49%;
  background-color: $brand_main;
  padding: 40px 60px;
  text-align: left;
  position: relative;
}

.admin .app__login-panel-sso {
  box-sizing: border-box;
  width: 500px;
  height: 111px;
  border-radius: 8px;
  box-shadow: 0 0 16px 8px $admin_glow;
  margin: 0 auto;
  padding: 30px 30px 0;
  font-size: 14px;
  margin-bottom: 48px;
  position: relative;
}

.admin .app__login-panel-sso .admin__error-div--welcome {
  top: 50px;
  font-size: 16px;
}

.admin .app__login-panel p {
  color: $brand_accent;
  font-size: 13px;
  font-weight: 300;
  margin: 0 0 40px 0;
}

.admin .app__login-panel label > p {
  margin: 0 0 2px;
  padding: 0;
  color: white;
  text-transform: uppercase;
  text-align: left;
  font-size: 12px;
}

.admin .app__login-panel p.no-link {
  margin-top: 74px;
}

.admin .app__login-panel p.welcome {
  font-size: 16px;
  opacity: 0.6;
}

.admin .app__login-panel .admin__input-text--alt + p {
  color: $admin_text;
}

.admin .app__login-panel p.forgotPassword {
  float: right;
  color: $brand_accent;
  margin-top: -10px;
}

.admin .app__login-panel p.forgotPassword a {
  color: $brand_accent;
  text-decoration: none;
  font-weight: 400;
}

.admin .app__login-panel__title {
  color: white;
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  margin-bottom: 4px;
  font-weight: 300;
  text-transform: uppercase;
}

.admin .app__login-panel__title.app__login-panel__title--welcome {
  margin-bottom: 8px;
}

.admin .admin__form {
  margin-top: 5px;
}

.admin .admin__form.admin__form--forgot {
  margin-top: 55px;
}

.admin .admin__input-text {
  //this is the closed-box version from Reset Password
  display: block;
  height: 36px;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  margin-bottom: 10px;
  font-family: $main_font;
  padding-left: 5px;
  font-size: 12px;
}

.admin .admin__input-text.admin__input-text--alt {
  //this is the open line version from Forgot Password
  border: 0;
  border-bottom: 1px solid $admin_form_element2;
  margin-bottom: 19px;
  padding-left: 1px;
  height: 25px;
  font-weight: bold;
}

.admin .admin__input-text.email-input::before {
  content: "mail";
  width: 18px;
  height: 15px;
}

.admin .admin__button {
  background-color: black;
  color: $text_white;
  border-radius: 0;
  height: 30px;
  width: 148px;
  font-size: 12px;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0;
  cursor: pointer;
  bottom: 114px;
  border: none;
  text-transform: uppercase;
  float: right;
  margin: 34px 0 15px;

  &.send__reset {
    margin-top: 55px;
  }

  i {
    color: $brand_accent;
    font-size: 10px;
    margin: 0 0 0 3px;
    position: relative;
    top: 0;
  }
}

.admin .admin__logo {
  height: 125px;
  width: 222px;
  background-image: url(/img/maci_login.png);
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
  left: 28px;
  bottom: 24px;
}

.admin__error-div {
  width: 100%;
  font-size: 12px;
  color: $brand_accent;
  font-weight: 400;
  font-family: $main_font;
  text-align: center;
  position: absolute;
  padding: 5px 0px;
  left: 0;
}

.admin__error-div--resetpassword {
  top: 258px;
}

.admin__error-div--welcome {
  top: 258px;
}

.app__subheader {
  box-sizing: border-box;
  font-family: $main_font;
  text-align: center;

  letter-spacing: -0.1px;
  font-size: 12px;
  color: $yet_another_grey;
  width: 100%;
}

.app__subheader__list {
  list-style: none;
  padding-inline-start: 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  border: 1px solid $brand_alt;
  display: flex;
}

.app__subheader__crumb {
  flex-grow: 1;
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: $brand_main;
  height: 42px;
  background-color: $light_grey;
  padding-top: 12px;
  /*border-right: 1px solid $brand_alt;*/
  position: relative;
  &:last-of-type {
    margin-right: 0;
    border-right: 0;
  }
  &.meow {
    background-color: $header_color;
    color: white;
  &:after {
    content: " ";
    display: block;
    background-image: url(/img/list-end--back.svg);
    height: 42px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    position: absolute;
    top: 0;
    right: -20px;
    z-index: 10;
  }
}
}

.app__subheader__crumb--selected {
  background-color: $brand_accent;
  &:after {
    content: " ";
    display: block;
    background-image: url(/img/list-end.svg);
    height: 42px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    position: absolute;
    top: 0;
    right: -20px;
    z-index: 10;
  }
  &:last-of-type {
   &:after {
    content: none;
   }
  }
}

.app__top-line {
  width: 100%;
  height: 1px;
  background-color: $table-border;
  margin: 0;
}
.app__icon {
  width: 61px;
  height: 61px;
  border-radius: 50%;
  background-image: url(/img/user.png);
  background-size: cover;
  cursor: pointer;
  margin-top: 8px;
}

.app__bignumber {
  color: $text_white;
  box-sizing: border-box;
  font-size: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: $secondary_form_color;
  display: inline-block;
  font-weight: bold;
  padding-top: 5px;
  margin-right: 5px;
  text-align: center;
}

.app__subheader__crumb--selected .app__bignumber {
  background-color: $form_element_color;
  color: $text_white;
}

.skin2 {
  .app__bignumber {
    background-color: $secondary_form_color_2;
  }
  .app__subheader__crumb--selected .app__bignumber {
    background-color: $form_element_color_2;
  }
  .app__subheader__crumb--selected {
    color: $form_element_color_2;
  }
}

.skin3 {
  .app__bignumber {
    background-color: $secondary_form_color_3;
  }
  .app__subheader__crumb--selected .app__bignumber {
    background-color: $form_element_color_3;
  }
  .app__subheader__crumb--selected {
    color: $form_element_color_3;
  }
}

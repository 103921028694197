.isi {
	background-color: white;
	width: 100%;
	position: fixed;
	top: 60vh;
	z-index: 20;
	padding-bottom: 100px;
	p {
		font-size: 16px;
		line-height: 23px;
	}
	h5 {
		font-size: 16px;
		font-weight: 700;
	}
	a {
		color: $brand-alt;
	}
}

.isi--open {
	transition: .4s;
	top: 123px;
	position: fixed;
	.isi__main {
		overflow-y: auto;
		height: calc(100vh - 160px);
	}
}

.isi--bottom-anchor {
	transition: .4s;
	position: fixed;

	top: calc(100% - 32px);
}

.isi__main {
	padding: 0 40px;
	background-color: white;
}

.isi .col-lg-6 {
	width: 50%;
	float: left;
	padding: 0 20px;
}

.isi__top {
	background-color: black;
	color: $brand_accent;
	padding: 5px 60px;
	h4 {
		font-size: 16px;
		font-weight: 500;
		margin: 0;
		display: block;
		float: left;
	}
	i {
		font-size: 18px;
		position: relative;
		top: 1px;
	}
}

.isi__openclose {
	float: right;
	cursor: pointer;
}


/////////


.isi--inline {
	background-color: #e5e4e5;
	padding: 40px 20px;
	margin-bottom: 40px;
	margin-top: 20px;
	p {
		font-size: 12px;
		line-height: 17px;
		font-weight: 300;
		margin-top: 4px;
	}
	h4 {
		font-size: 18px;
		font-weight: 300;
		text-transform: uppercase;
		margin: 0 0 5px;
	}
	a {
		color: $brand-alt;
	}
}

.isi--inline .col-md-6 {
	width: 50%;
	float: left;
	padding: 0 20px;
	}

.isi__collapse {
	height: 0;
	overflow: hidden;
	transition: 2.4s;
}

.isi__collapse.open {
	height: 500px;
}

.isi__expand {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 700;
	text-decoration: underline;
	cursor: pointer;
	letter-spacing: 1px;
}
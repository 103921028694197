.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

iframe {
  border: 1px solid gray;
}

.hidden {
  display: none;
}

/** custom **/
.error {
  color: #ed7622;
  padding: 15px 0px;
}
header button {
  float: left;
  cursor: pointer;
}

header .brandTabs {
  margin: 0px 200px;
  width: 50%;
}

.brandTabs div {
  cursor: pointer;
  float: left;
  padding: 0px 30px;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 0px 20px;
}
header img {
  float: right;
  width: 38px;
  clear: right;
  cursor: pointer;
}

.composerContainer {
  text-align: center;
}
.steps span {
  padding: 10px;
}
.templateSelectors button {
  margin: 10px;
  padding: 10px 40px;
  background-color: black;
  color: white;
}

.gridContainer.hidden,
.templateContainer.hidden {
  display: none;
}

.composerNav {
  position: absolute;
  bottom: 0;
  width: 80%;
  // margin-left: 10%;
  // padding-bottom: 30px;
}

.composerNav div {
  padding: 20px;
  background-color: #75c375;
  color: white;
}
.composerNav .prev {
  float: left;
  display: block;
  cursor: pointer;

  &.hidden {
    display: none;
  }
}
.composerNav .next {
  float: right;
  display: block;
  cursor: pointer;

  &.hidden {
    display: none;
  }
}

.brandOne {
  .templateSelectors button {
    background-color: blue;
  }
}

.brandTwo {
  .templateSelectors button {
    background-color: red;
  }
}

.profileContainer {
  button.logOut {
    margin: 20px 0px;
    float: right;
  }

  .editProfile {
    clear: both;
    margin: 40px 0px;
  }

  .changePassword {
    margin: 40px 0px;
  }

  .changePassword input {
    margin: 0px 10px;
    padding: 10px;
  }

  button.changePassword {
    padding: 10px;
  }

  .userActivity {
    table {
      width: 100%;
      text-align: left;

      th {
        width: 15%;
        padding: 10px;
        color: white;
        background: purple;
      }

      tbody {
        td {
          width: 15%;
          padding: 10px;
        }
      }
    }
  }
}

//------------


